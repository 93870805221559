import { tailwind } from '@theme-ui/presets'

const heading = {
  color: tailwind.colors.black,
  fontFamily: `heading`,
  fontWeight: `heading`,
  lineHeight: `heading`,
  variant: 'text.heading',
  mt: [3, 4],
  mb: [2, 3],
}

export default {
  ...tailwind,
  initialColorMode: `cookie`,
  useCustomProperties: true,
  fontSizes: [
    `0.875rem`,
    `1rem`,
    `1.25rem`,
    `1.5rem`,
    `1.875rem`,
    `2.25rem`,
    `3rem`,
    `4rem`,
    `4.5rem`,
  ],
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'system-ui, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  text: {
    heading: {
      color: tailwind.colors.black,
    },
  },
  colors: {
    ...tailwind.colors,
    background: `#fff`,
    primary: tailwind.colors.black,
    text: tailwind.colors.gray[7],
    muted: tailwind.colors.gray[7],
    modes: {
      tempura: {
        background: `#f8f8f8`,
        primary: tailwind.colors.red[5],
        text: tailwind.colors.gray[6],
      },
    },
  },
  styles: {
    ...tailwind.styles,
    root: {
      ...tailwind.styles.root,
      color: `muted`,
      backgroundColor: `background`,
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      h1: {
        ...heading,
        fontSize: [5, 6],
      },
      h2: {
        ...heading,
        fontSize: [4, 5],
      },
      h3: {
        ...heading,
        fontSize: [3, 4],
      },
      h4: {
        ...heading,
        fontSize: [2, 3],
      },
      h5: {
        ...heading,
        fontSize: [1, 2],
      },
      h6: {
        ...heading,
        fontSize: 1,
      },
      p: {
        fontSize: [1],
        letterSpacing: `-0.003em`,
        lineHeight: `body`,
        '--baseline-multiplier': 0.179,
        '--x-height-multiplier': 0.35,
      },
    },
    Footer: {
      textAlign: `center`,
      display: `block`,
      color: `textMuted`,
      px: [2, 3],
      py: [3, 4],
      div: {
        py: 2,
      },
    },
    Header: {
      py: [2, 3],
      fontSize: 2,
      display: `flex`,
      justifyContent: `space-between`,
      alignItems: `center`,
      flexWrap: `wrap`,
      a: {
        color: `text`,
        textDecoration: `none`,
      },
    },
    Main: {
      ...tailwind.styles.Main,
      position: `relative`,
    },
    Container: {
      maxWidth: `6xl`,
    },
  },
  buttons: {
    toggle: {
      color: `background`,
      border: `none`,
      backgroundColor: `text`,
      cursor: `pointer`,
      alignSelf: `center`,
      px: 3,
      py: 2,
      ml: 3,
    },
  },
}
